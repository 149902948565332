<template>
  <div
    class="fixed w-full h-screen top-0 left-0 bg-bgDarkest flex flex-col justify-center items-center z-20 overflow-visible"
  >
    <LoadingAnimation />
    <img src="@/assets/logo.png" class="w-[200px] mt-[20px] animationFadeIn" />
  </div>
</template>

<script>
import LoadingAnimation from "@/components/LoadingAnimation.vue";

export default {
  name: "LoadingScreen",
  components: { LoadingAnimation },
  props: {
    screentype: {
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #8556e1;
}
</style>
