<template>
  <div
    v-if="!getIsInstalled()"
    @click="installed = true"
    :class="installed ? 'bottom-[-30vh]' : 'bottom-[-30vh]'"
    class="fixed h-[30vh] bottom-[-30vh] left-0 w-full p-5 bg-bgLightest centerAll flex-col z-10 microTran"
  >
    <p class="text-white pb-5 text-s text-center">
      Du kannst unsere App übrigens auch installieren!
    </p>
    <p @click="declinePwa()" class="text-secondary pb-5 text-xxs text-center">
      Nein danke!
    </p>
    <button
      @click="installPwa()"
      class="text-center w-full bg-accent md:hover:bg-accent text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Jetzt installieren
    </button>
  </div>
</template>

<script>
export default {
  name: "InstallPwa",
  data() {
    return {
      deferredPrompt: null,
      installed: false,
    };
  },
  methods: {
    getIsInstalled() {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        this.installed = true;
        return window.matchMedia("(display-mode: standalone)").matches;
      } else {
        return false;
      }
    },
    declinePwa() {
      this.installed = true;
      // set cookie to remember choice
    },
    async installPwa() {
      this.deferredPrompt.prompt();
    },
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
  },
};
</script>

<style scoped></style>
