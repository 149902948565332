<template>
  <div>CookiesModal</div>
</template>

<script>
export default {
  name: "CookiesModal",
  data() {
    return {
      cookiesAllowed: false,
      loopInterval: null,
    };
  },
  mounted() {
    if (window.localStorage.getItem("cookiesAllowed") === "true") {
      this.cookiesAllowed = true;
    }
    if (!this.cookiesAllowed) {
      this.loopInterval = setInterval(() => {
        this.iFrameLoop();
      }, 1000);
    }
  },
  beforeUnmount() {
    window.clearInterval(this.loopInterval);
  },
  methods: {
    iFrameLoop() {
      let i, frames;
      frames = document.getElementsByTagName("iframe");
      for (i = 0; i < frames.length; ++i) {
        if (frames[i].parentNode.querySelector(".cookieBanner") === null) {
          frames[i].location = "";
          let modal = document.createElement("div");
          modal.classList.add("cookieBanner");
          let headline = document.createElement("h2");
          headline.appendChild(
            document.createTextNode("Inhalte von Youtube & Co. anzeigen?")
          );
          let button = document.createElement("button");
          button.onclick = () => {
            this.cookiesAllowed = true;
            window.localStorage.setItem("cookiesAllowed", true);
            modal.style.display = "none";
            window.clearInterval(this.loopInterval);
          };
          button.appendChild(document.createTextNode("Inhalte anzeigen"));
          button.classList.add("defaultBtn");
          modal.appendChild(headline);
          modal.appendChild(button);
          frames[i].parentNode.insertBefore(modal, frames[i]);
          frames[i].parentNode.style.position = "relative";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.cookieBanner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: var(--bgDark);
  color: #fff;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}
</style>
