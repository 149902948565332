<template>
  <div
    class="flex rounded-full justify-center items-center icon-background-size microTran"
    :class="getIsRocket ? 'bg-primary' : 'bg-bgLightest'"
  >
    <span
      class="material-icons-outlined text-base leading-[30px] md:hover:text-white microTran"
      :class="getIsRocket ? 'text-white' : 'text-primary'"
      @click.self="toggleRocket()"
      >rocket_launch</span
    >
  </div>
</template>

<script>
export default {
  name: "addRocket",
  computed: {
    getIsRocket() {
      let found = false;
      let error;
      let subCoins = this.$store.state.user.info.acf.subscribed_coins;
      if (subCoins != undefined) {
        try {
          for (let i = 0; i < subCoins.length; i++) {
            if (subCoins[i].slug == this.slug.toLowerCase()) {
              found = true;
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-unused-vars
          error = e;
          console.error(error);
        }
      }
      return found;
    },
  },
  methods: {
    async toggleRocket() {
      const data = {
        slug: this.slug.toLowerCase(),
        like: !this.getIsRocket,
      };
      const response = await this.$store.dispatch("setTop100Favourites", data);
      let responseArray = [];
      if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
          responseArray.push({ slug: response[i] });
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(response)) {
          responseArray.push({ slug: value });
        }
      }
      console.log(responseArray);
      this.$store.commit("SET_TOP100_COINS_IN_USER", responseArray);
    },
  },
  props: {
    slug: String,
  },
};
</script>
