import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/musterdepot",
    name: "Musterdepot",
    component: () =>
      import(/* webpackChunkName: "musterdepot" */ "../views/DepotView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stream",
    name: "Stream",
    component: () =>
      import(/* webpackChunkName: "stream" */ "../views/NewsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/top100",
    name: "Top 100",
    component: () =>
      import(/* webpackChunkName: "top100" */ "../views/Top100View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mitteilungen",
    name: "Mitteilungen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotificationView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // LOGIN //
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  // USERSETTINGS //
  {
    path: "/usersettings",
    name: "Einstellungen",
    component: () =>
      import(
        /* webpackChunkName: "usersettings" */ "../views/UserSettings.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/webinare",
    name: "Webinare",
    component: () =>
      import(
        /* webpackChunkName: "WebinareView" */ "../views/WebinareView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/zupremium",
    name: "zupremium",
    component: () =>
      import(/* webpackChunkName: "zupremium" */ "../views/ZuPremium.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // TAGESTHREAD //
  {
    path: "/tagesforum",
    name: "Tagesforum",
    component: () =>
      import(/* webpackChunkName: "tagesforum" */ "../views/TagesThread"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/archiv",
    name: "Archiv",
    component: () =>
      import(/* webpackChunkName: "archiv" */ "../views/ThreadArchieve"),
    meta: {
      requiresAuth: true,
    },
  },
  // DYNAMIC USER //
  {
    path: "/user/:id",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/UserProfile"),
    meta: {
      requiresAuth: true,
    },
  },
  // DYNAMIC THREAD //
  {
    path: "/archiv/:id",
    name: "Einzelarchiv",
    component: () =>
      import(
        /* webpackChunkName: "einzelarchiv" */ "../views/ThreadArchieveSingle"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // DYNAMIC AREA //
  {
    path: "/news/:id",
    name: "Beitrag",
    component: () =>
      import(/* webpackChunkName: "newsbeitrag" */ "../views/SinglePost.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help/:id",
    name: "Help",
    component: () =>
      import(/* webpackChunkName: "helpbeitrag" */ "../views/SinglePost.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/library/:id",
    name: "Library",
    component: () =>
      import(
        /* webpackChunkName: "librarybeitrag" */ "../views/SingleLibraryEntry.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/top100/chart/:coin",
    name: "Chartanalyse",
    component: () =>
      import(
        /* webpackChunkName: "chartanalyse" */ "../views/AnalysisView.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/top100/fundament/:coin",
    name: "Fundamentanalyse",
    component: () =>
      import(
        /* webpackChunkName: "fundamentanalyse" */ "../views/AnalysisView.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nettiquette",
    name: "Nettiquette",
    component: () =>
      import(
        /* webpackChunkName: "fundamentanalyse" */ "../views/NettiQuette.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

function hasTokenInCache() {
  return window.$cookies.get("user") ? true : false;
}

function getUserFromCache() {
  return window.$cookies.get("user");
}

async function requestUserDataAfterTokenAuth() {
  return await store.dispatch("requestUserInformation");
}

async function requestAppDataAfterTokenAuth() {
  await store.dispatch("requestTagesthread");
  await store.dispatch("requestTagesthreadCommentInfo");
  await store.dispatch("requestTop100Coins");
  await store.dispatch("requestNewsList");
  await store.dispatch("requestGetWebinars");
  await store.dispatch("requestHelpList");
  await store.dispatch("getRequestNotifications");
}

function safeTokenInStore(user) {
  store.commit("SET_USER", { token: user.token });
}

router.beforeEach(async (to, from, next) => {
  if (to.path === "/login" && store.state.user) {
    next({ path: "/" });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // route requires auth, check if user is logged in
    // if not, redirect to login page.
    if (hasTokenInCache() && !store.state.user) {
      try {
        // eslint-disable-next-line no-unused-vars
        await store.dispatch("validate", getUserFromCache().token);
        // now token is valid, ready to set user
        // now request stuff
        safeTokenInStore(getUserFromCache());
        const userData = await requestUserDataAfterTokenAuth();
        if (userData.membership_level === "none") {
          throw new TypeError("noMembership");
        }
        next();
        await requestAppDataAfterTokenAuth();
      } catch (e) {
        // the token is invalid so we will have the user login again
        // clear the token and user info
        store.commit("DELETE_USER");
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    } else if (!store.state.user) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      // we have a state.user object but
      // we need to check if the token is still valid
      try {
        // eslint-disable-next-line no-unused-vars
        const { status } = await store.dispatch("validate");
        if (store.state.user.membership_level === "none") {
          throw new TypeError("noMembership");
        }
        console.log(to);
        next();
      } catch (e) {
        // the token is invalid so we will have the user login again
        // clear the token and user info
        store.commit("DELETE_USER");
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    }
  } else {
    // this is not a protected route
    next();
  }
});

export default router;
