<template>
  <div class="rounded-full" @click="goToProfile">
    <img
      v-if="getUserImage != null"
      :src="getUserImage"
      class="rounded-full userimage-defaultSize"
    />
  </div>
</template>

<script>
export default {
  name: "UserImage",
  data() {
    return {
      dataReady: false,
    };
  },
  computed: {
    getUserImage: function () {
      let returnVal = null;
      if (this.$store.state.user != undefined) {
        if (this.$store.state.user.info != undefined) {
          returnVal =
            this.$store.state.user.info.acf.user_image.sizes.thumbnail;
        }
      }
      return returnVal;
    },
  },
  methods: {
    goToProfile: function () {
      this.$router.push("/userSettings");
    },
  },
};
</script>

<style scoped></style>
