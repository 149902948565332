<template>
  <div
    class="fixed top-0 w-full h-[56px] md:h-[120px] bg-[rgba(26,16,46,0.9)] backdrop-blur-sm md:bg-transparent flex flex-row justify-evenly items-end z-1 md:max-w-[925px]"
  >
    <div
      class="w-full h-[56px] md:bg-transparent flex flex-row justify-evenly items-end md:hidden"
    >
      <div
        :style="'width:' + 100 / options.length + '%'"
        class="flex justify-center align-bottom"
        v-for="(option, index, key) in options"
        @click="changeValue(option.slug, option.title)"
        :key="key"
      >
        <div
          class="relative text-secondary mb-2 microTran cursor-pointer microTran md:hover:text-white"
          :class="active == option.title ? 'active' : ''"
        >
          <b>{{ option.title }}</b>
        </div>
        <transition name="list">
          <hr
            v-if="active == option.title"
            class="absolute bg-primary h-[2px] top-[54px]"
            :style="{ width: Math.round(100 / options.length) + '%' }"
          />
        </transition>
        <hr
          v-if="active != option.title"
          class="absolute bg-bgLightest h-[2px] top-[54px]"
          :style="{ width: Math.round(100 / options.length) + '%' }"
        />
      </div>
    </div>
    <div
      class="hidden top-0 md:flex w-full flex flex-row justify-start items-center self-center h-[112px]"
    >
      <a
        href="javascript:void(0);"
        class="flex justify-center align-bottom relative overflow-visible"
        v-for="(option, index, key) in options"
        @click="changeValue(option.slug, option.title)"
        :key="key"
      >
        <p
          class="text-secondary font-bold text-lg mb-2 pr-[46px] microTran md:hover:text-white"
          :class="active == option.title ? 'active' : ''"
        >
          {{ option.title }}
          <slot v-if="option.withProps"> </slot>
        </p>
        <transition name="list">
          <hr
            v-if="active == option.title"
            class="absolute bg-primary h-[4px] w-[calc(100%_-_46px)] bottom-[-20px] left-0"
          />
        </transition>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondaryTabbar",
  data() {
    return {
      active: this.options[0].title,
    };
  },
  props: {
    options: Array,
  },
  methods: {
    changeValue(to, toTitle) {
      this.active = toTitle;
      this.$emit("changeVal", to);
    },
  },
};
</script>

<style scoped>
.active {
  color: white !important;
}
hr {
  border-top-width: 0px;
}
</style>
