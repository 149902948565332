<template>
  <div
    class="bg-bgDarkest min-h-screen w-full customBgImage block md:flex relative pb-4"
  >
    <router-view v-slot="{ Component, route }">
      <Transition name="list" mode="out-in">
        <component
          :key="route.path"
          :is="Component"
          :class="
            $store.state.user != null
              ? ['md:max-w-[1200px]', 'md:pl-[260px]', 'md:pr-[20px]']
              : ['md:max-w-[100%]', 'md:pl-[100px]', 'md:pr-[100px]']
          "
          class="w-full md:min-w-[750px] md:pl-[260px]"
        />
      </Transition>
    </router-view>
    <div
      :class="
        $route.path == '/top100'
          ? ['translate-x-[500px]', 'opacity-0']
          : ['translate-x-[0px]', 'opacity-1']
      "
      v-if="$store.state.user != null && $store.state.screenSizes.width > 1650"
      class="customTransitionTop100 overflow-x-hidden w-[480px] fixed right-0 top-0"
    >
      <Top100View class="px-2 max-h-screen overflow-y-auto" />
    </div>
    <InstallPwa />
    <MainTabbar v-if="$store.state.user != null" />
  </div>
</template>

<script>
import MainTabbar from "@/components/MainTabbar";
import InstallPwa from "@/components/InstallPwa";
import Top100List from "@/components/Top100List";
import Top100View from "@/views/Top100View";
import LoadingScreen from "@/components/LoadingScreen";
import CookiesModal from "@/components/CookiesModal.vue";

export default {
  title: "App",
  components: {
    CookiesModal,
    LoadingScreen,
    Top100View,
    MainTabbar,
    InstallPwa,
    Top100List,
  },
  data() {
    return {
      notificationRequestInterval: setInterval(
        this.requestNotifications,
        10000
      ),
      streamRequestInterval: setInterval(this.requestStream, 60000),
      top100RequestInterval: null,
    };
  },
  mounted() {
    this.startCheckForCoinUpdateLoop();
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
  },
  beforeUnmount() {
    clearInterval(this.notificationRequestInterval);
    clearInterval(this.top100RequestInterval);
    clearInterval(this.streamRequestInterval);
    window.removeEventListener("resize", this.getDimensions);
  },
  computed: {
    getCoinListFavourites() {
      if (this.$store.state.coins.top100 == null) return [];
      return this.$store.state.coins.favourites;
    },
  },
  methods: {
    startCheckForCoinUpdateLoop() {
      let minutesNow = new Date();
      minutesNow.setTime(Date.now());
      //let oldMinute = minutesNow.getSeconds();
      let checkupInterval = setInterval(() => {
        minutesNow.setTime(Date.now());
        if (minutesNow.getSeconds() % 10 == 0) {
          killCheckupIntervalStartRequestInterval(this);
        }
      }, 1000);
      function killCheckupIntervalStartRequestInterval(thisCcntext) {
        clearInterval(checkupInterval);
        thisCcntext.requestTop100();
        thisCcntext.top100RequestInterval = setInterval(
          thisCcntext.requestTop100,
          10000
        );
      }
    },
    getDimensions() {
      this.$store.commit("SET_WIDTH", document.documentElement.clientWidth);
      this.$store.commit("SET_HEIGHT", document.documentElement.clientHeight);
      if (document.documentElement.clientWidth > 768) {
        this.$store.commit("SET_RESPONSIVE_MODE", "desktop");
      } else {
        this.$store.commit("SET_RESPONSIVE_MODE", "mobile");
      }
    },
    requestStream() {
      if (this.$store.state.user != null) {
        this.$store.dispatch("requestNewsList");
        this.$store.dispatch("requestHelpList");
        this.$store.dispatch("requestSchoolList");
      }
    },
    requestNotifications() {
      if (this.$store.state.user != null) {
        this.$store.dispatch("getRequestNotifications");
      }
    },
    requestTop100() {
      if (this.$store.state.user != null) {
        this.$store.dispatch("requestTop100Coins");
      }
    },
  },
};
</script>

<style lang="scss">
@import "https://chartexperten.de/wp-json/chartexperten/v1/global_stylesheet.css";
body,
html {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.customBgImage {
  background-image: url("./assets/bg-logo.png");
  background-position: left 200px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}
@media only screen and (min-width: 768px) {
  .customBgImage {
    background-position: 240px 150px;
  }
}
.customTransitionTop100 {
  transition: opacity 0.3s linear, transform 0.8s ease;
}
</style>
