<template>
  <div>
    <div
      class="h-[35px] mx-2 my-3 p-1 left-0 w-[calc( 100% - 0.5rem )] centerAll border border-bgLightest rounded-full overflow-visible"
    >
      <input
        v-model="searchTitle"
        placeholder="Suche"
        name="searchbar"
        class="rounded m-auto text-secondary border-2 w-full mx-2 placeholder-secondary"
      />
      <div class="centerAll rounded-full w-[40px] h-[33px]">
        <span class="material-icons-outlined text-secondary"> search </span>
      </div>
    </div>
    <div
      v-for="coin in getCoins"
      :key="coin.code"
      class="md:hover:opacity-80 microTran"
    >
      <single-coin
        :key="coin.code"
        :coin="coin"
        :coin-rank="coin.rank"
        :isRocket="coin.isRocket"
        :coinDetails="coin"
        :type="'top100'"
      />
      <hr class="mx-2 border-bgLightest" />
    </div>
  </div>
</template>

<script>
import SingleCoin from "@/components/SingleCoin.vue";

export default {
  name: "Top100List",
  components: {
    SingleCoin,
  },
  props: {
    getCoinInfo: Object,
  },
  data() {
    return {
      openDetails: false,
      loadCoins: 10,
      loadCoinsBottom: 0,
      searchTitle: "",
    };
  },
  computed: {
    getCoins() {
      if (this.getCoinInfo == null) return [];
      const coinList = this.getCoinInfo.map((obj) => ({
        ...obj,
        isRocket: false,
      }));
      if (this.searchTitle != "") {
        return coinList.filter(
          (coin) =>
            coin.name.toLowerCase().includes(this.searchTitle.toLowerCase()) ||
            coin.code.toLowerCase().includes(this.searchTitle.toLowerCase())
        );
      }
      return coinList;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
