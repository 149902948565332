export default {
  methods: {
    getPreviewNewsTime(newsTime) {
      let postDate = new Date(newsTime);
      let now = Date.now();

      const datediff = (first, second) => {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
      };
      const day = 60 * 60 * 24 * 1000;
      if (postDate > now - day) {
        const hours = `0${new Date(now - postDate).getHours() - 1}`.slice(-2);
        if (hours == 0) {
          const minutes = `0${new Date(now - postDate).getMinutes()}`.slice(-2);
          return "vor " + minutes + " Min.";
        } else {
          if (hours > 0) {
            return "vor " + hours + " Std.";
          } else {
            return "jetzt";
          }
        }
      } else if (postDate > now - day * 2) {
        return "vor 1 Tag";
      } else if (postDate > now - day * 3) {
        return "älter als 1 Tag";
      } else {
        return "älter als " + datediff(postDate, now) + " Tage";
      }
    },
    getHumanReadable(amount) {
      // Nine Zeroes for Billions
      return Math.abs(Number(amount)) >= 1.0e9
        ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + " Billionen"
        : // Six Zeroes for Millions
        Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + " Millionen"
        : // Three Zeroes for Thousands
        Math.abs(Number(amount)) >= 1.0e3
        ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + " K"
        : Math.abs(Number(amount));
    },
    getReadableDate(date) {
      // note parts[1]-1
      return new Date(date).toLocaleDateString();
    },
    threadDateTimeComments(oldDateTime) {
      let dateTimeString;
      if (oldDateTime != undefined) {
        const date = new Date(oldDateTime).toLocaleDateString("de-DE");
        const time = new Date(oldDateTime)
          .toLocaleTimeString("de-DE")
          .slice(0, -3);
        if (this.isToday(new Date(oldDateTime))) {
          dateTimeString = "heute, um " + time;
        } else if (this.isYesterday(new Date(oldDateTime))) {
          dateTimeString = "gestern, um " + time;
        } else {
          dateTimeString = date + " um " + time;
        }
      } else {
        dateTimeString = "";
      }
      return dateTimeString;
    },
    getThreadDateInfo(oldDateTime) {
      let dateTimeString;
      if (oldDateTime != undefined) {
        dateTimeString = new Date(oldDateTime);
        dateTimeString = dateTimeString.toLocaleDateString(
          "de-DE",
          this.localeDateTimeStringOptions
        );
      } else {
        dateTimeString = "";
      }
      return dateTimeString;
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
      );
    },
    isYesterday(date) {
      const today = new Date();
      return (
        date.getDate() == today.getDate() - 1 &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
      );
    },
  },
};
