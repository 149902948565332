<script>
export default {
  name: "LoadingAnimation",
};
</script>

<template>
  <div class="loading animationFadeIn overflow-visible">
    <div class="loading-1"></div>
    <div class="loading-2"></div>
    <div class="loading-3"></div>
    <div class="loading-4"></div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  height: 120px;
  padding: 0px;
  width: 120px;
  padding: 10px;
  box-sizing: border-box;
}
.animationFadeIn {
  animation: fadeIn 0.4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading {
  0% {
    background-color: #8556e1;
  }

  30% {
    background-color: #c4aeef;
  }
  50% {
    height: 100px;
    margin-top: 0px;
  }
  80% {
    background-color: #8556e1;
  }
  100% {
    background-color: #c4aeef;
  }
}
/*@-moz-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@-o-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}*/
@mixin inner() {
  height: 10px;
  width: 20px;
  background-color: #8556e1;
  display: inline-block;
  margin-top: 90px;
  opacity: 0.8;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
@mixin loading() {
  @for $i from 1 through 10 {
    .loading-#{$i} {
      @include inner();
      -webkit-animation-delay: $i/4 + s;
      animation-delay: $i/4 + s;
    }
  }
}
.loading {
  @include loading();
}
</style>
