import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";

import "material-icons/iconfont/filled.css";
import "material-icons/iconfont/outlined.css";

import "./style.scss";
import "./transitions.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookies, { expire: "7d" })
  .mount("#app");
