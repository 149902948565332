<template>
  <div class="bg-bgDarkest md:bg-transparent min-h-screen">
    <div v-if="dataReady">
      <SecondaryTabbar
        :options="[
          { title: 'Top 100', slug: 'Top100' },
          { title: 'Meine Favoriten', slug: 'myCoins' },
        ]"
        @changeVal="changeActive"
      />
      <div class="pb-[56px] md:pb-[112px]"></div>
      <div>
        <transition name="list" mode="out-in">
          <div :key="'top100'" v-if="currentActive == 'Top100'">
            <Top100List :getCoinInfo="getCoinList" />
            <div
              v-if="isTop100Page()"
              class="flex flex-col justify-between z-10 md:hover:cursor-pointer"
              @click.self.passive="openCoinsFaq = !openCoinsFaq"
            >
              <div
                class="flex justify-between mx-2 my-3 text-white font-bold mx-2 overflow-visible cursor-pointer"
                @click.stop="openCoinsFaq = !openCoinsFaq"
              >
                <div class="flex">
                  <img
                    class="h-[30px] mr-0.5 ml-2"
                    src="../assets/chartexperten-icon.svg"
                  />
                  <p class="ml-2.5">Top 100 FAQ's</p>
                </div>
                <p
                  class="flex rounded-full justify-center items-center icon-background-size bg-bgLightest ml-2 microTran"
                  :class="openCoinsFaq ? 'bg-primary' : ''"
                  @click.passive="openCoinsFaq = !openCoinsFaq"
                >
                  <span
                    class="material-icons-outlined microTran md:hover:text-white"
                    @click.passive="openCoinsFaq = !openCoinsFaq"
                    :class="
                      openCoinsFaq
                        ? ['text-white', 'rotate-180']
                        : 'text-primary'
                    "
                  >
                    expand_more
                  </span>
                </p>
              </div>
              <div class="ml-2">
                <div class="m-2" v-if="openCoinsFaq">
                  <div v-for="(faq, index, key) in coinFaq" :key="key">
                    <div>
                      <div
                        class="my-2 flex justify-between flex-nowrap"
                        @click="faq.isOpen = !faq.isOpen"
                      >
                        <p class="w-[88%]">
                          <b>{{ faq.title }}</b>
                        </p>
                        <span
                          v-if="!faq.isOpen"
                          class="material-icons-outlined mr-0.5"
                        >
                          add
                        </span>
                        <span
                          v-if="faq.isOpen"
                          class="material-icons-outlined mr-0.5"
                        >
                          remove
                        </span>
                      </div>
                      <p v-if="faq.isOpen" v-html="faq.content"></p>
                    </div>
                    <hr class="border-bgLightest" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :key="'myCoins'" v-else-if="currentActive == 'myCoins'">
            <Top100List :getCoinInfo="getCoinListFavourites" />
            <p
              v-if="
                getCoinListFavourites == null ||
                getCoinListFavourites.length == 0
              "
              class="text-white text-xs centerAll p-4 m-2 mt-8 rounded-[17px] bg-bgDark"
            >
              <a href="/top100" class="text-center">
                <span class="text-base"
                  >Klicke hier, um deine Favoriten zu abonnieren. <br />
                  Nutze dafür das Raketensymbol.</span
                >
                <br /><br />
                Diese werden dir dann hier in der Favoritenübersicht und auf
                deinem Home-Bildschirm angezeigt. Wenn du einen Favoriten
                hinzugefügt hast, wirst du automatisch benachrichtigt, sobald es
                eine neue Chart- oder Fundamentalanalyse gibt.
              </a>
            </p>
          </div>
        </transition>
      </div>
    </div>
    <LoadingScreen v-else />
  </div>
</template>

<script>
import Top100List from "@/components/Top100List";
import SecondaryTabbar from "@/components/SecondaryTabbar";
import LoadingScreen from "@/components/LoadingScreen";

export default {
  name: "Top100View",
  components: {
    LoadingScreen,
    SecondaryTabbar,
    Top100List,
  },
  methods: {
    changeActive(to) {
      this.currentActive = to;
    },
    isTop100Page() {
      return window.location.pathname == "/top100";
    },
  },
  watch: {
    async currentActive(newActive) {
      if (newActive == "myCoins") {
        await this.$store.dispatch("requestTop100Coins");
      }
    },
  },
  computed: {
    getCoinList() {
      if (this.$store.state.coins.top100 == null) return [];
      return this.$store.state.coins.top100;
    },
    getCoinListFavourites() {
      if (this.$store.state.coins.top100 == null) return [];
      return this.$store.state.coins.favourites;
    },
  },
  async mounted() {
    this.dataReady = true;
  },
  data() {
    return {
      currentActive: "Top100",
      dataReady: false,
      openCoinsFaq: false,
      coinFaq: [
        {
          title: "Wie werden Ränge der Top100 für Kryptowährungen bestimmt?",
          content:
            "Der Rang einer Kryptowährung in unserer Top100 wird durch \n \
          Livecoinwatch.com anhand der Marktkapitalisierung bestimmt. \n \
            Die Berechnung der Marktkapitalisierung erfolgt, indem der \n \
          volumengewichtete Durchschnittspreis mit seinem zirkulierenden \n \
          Angebot multipliziert wird. Ein Coin/Token muss mindestens an \n \
        zwei Börsen und drei Märkten gehandelt werden, um eingestuft \n \
        zu werden. Zudem können Platzierungen der Coins/Token \n \
        stellenweise von anderen Anbietern wie zum Beispiel \n \
        Coinmarketcap.com abweichen, da möglicherweise ein anderes \n \
        zirkulierendes Angebot hinterlegt ist. (Siehe FAQ = (Siehe FAQ \n \
          = Definition of Circulating/Total/Max Supply) Da wir Da wir \n \
    unsere Informationen und Kurse von Livecoinwatch beziehen, \n \
      können wir keine Gewähr für die Richtigkeit der Inhalte \n \
    übernehmen. Quelle: <a href='http://www.livecoinwatch.com' target='_blank'>http://www.livecoinwatch.com</a>",
          isOpen: false,
        },
        {
          title: "Wie wird der Preis einer Kryptowährung berechnet? ",
          content:
            "Die Preise werden von Livecoinwatch.com direkt von den Börsen\n" +
            "gezogen. Der Preis einer Kryptowährung wird anhand des\n" +
            "volumengewichteten Durchschnitts der globalen Marktpaare\n" +
            "berechnet, die auf Spotmärkten mit Handelsgebühren gehandelt\n" +
            "werden. Das bedeutet, dass das Volumen direkt damit\n" +
            "korreliert, wie viel Einfluss es auf den globalen\n" +
            "Durchschnittspreis hat. Da wir unsere Informationen und Kurse\n" +
            "von Livecoinwatch beziehen, können wir keine Gewähr für die\n" +
            "Richtigkeit der Kurse übernehmen. Quelle:\n" +
            "<a href='http://www.livecoinwatch.com' target='_blank'>http://www.livecoinwatch.com</a>\"",
          isOpen: false,
        },
        {
          title: "Wie wird das Gesamtvolumen berechnet?",
          content:
            "Das Gesamtvolumen errechnet sich aus der Summe der Volumina\n" +
            "aller aktiven Märkte. Alle in der Gesamtvolumenstatistik\n" +
            "enthaltenen Märkte sind Kassamärkte mit Handelsgebühren. Da\n" +
            "wir unsere Informationen und Kurse von Livecoinwatch beziehen,\n" +
            "können wir keine Gewähr für die Richtigkeit der Inhalte\n" +
            "übernehmen. <a href='http://www.livecoinwatch.com' target='_blank'>http://www.livecoinwatch.com</a>",
          isOpen: false,
        },
        {
          title: "Definition von Circulating/Total/Max Supply",
          content:
            "Circulating Supply ist die Menge, die innerhalb des Marktes\n" +
            "und in den Händen der Investoren zirkuliert. Gesperrte,\n" +
            "treuhänderisch hinterlegte, reservierte oder nicht handelbare\n" +
            "Coins werden nicht in die Umlaufbestandsberechnung einbezogen.\n" +
            "Das Gesamtangebot ist die Gesamtmenge der Coins/Token, die\n" +
            "erstellt wurden, ohne alle Coins/Token, die „verbrannt“\n" +
            "wurden. Max Supply st die maximale Menge an Coins/Token, die\n" +
            "in der Kryptowährung vorhanden sein wird, abhängig von ihrer\n" +
            "Plattform. Da wir unsere Informationen und Kurse von\n" +
            "Livecoinwatch beziehen, können wir keine Gewähr für die\n" +
            "Richtigkeit der Inhalte übernehmen. Quelle:\n" +
            "<a href='http://www.livecoinwatch.com' target='_blank'>http://www.livecoinwatch.com</a>",
          isOpen: false,
        },
      ],
    };
  },
};
</script>
