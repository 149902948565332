<template>
  <div>
    <div
      class="flex justify-between z-10 md:hover:cursor-pointer"
      @click.self.passive="toggleSingleCoin()"
    >
      <div class="flex mx-2 my-3" @click.stop="toggleSingleCoin()">
        <img class="w-[40px] h-[40px] mr-2" :src="coin.png32" />
        <div>
          <p class="font-bold leading-3 mt-1.5" whitespace-nowrap>
            {{ coin.code }}
          </p>
          <p class="text-secondary whitespace-nowrap">
            {{ coin.name }}
          </p>
        </div>
      </div>
      <div class="flex justify-end items-end">
        <p
          class="text-xs leading-[3rem] mr-2 whitespace-nowrap"
          @click.passive="toggleSingleCoin()"
        >
          {{
            parseFloat(
              coin.rate > 1 ? coin.rate.toFixed(2) : coin.rate.toFixed(6)
            )
          }}
          $
        </p>
        <div class="flex justify-center self-center mx-2 overflow-visible">
          <div
            @click="openChartanalysis"
            v-if="$store.state.screenSizes.mode == 'desktop'"
            class="flex items-center md:hover:cursor-pointer"
          >
            <p
              class="rounded-full bg-bgLightest icon-background-size centerAll"
            >
              <span
                class="material-icons-outlined text-primary md:hover:text-white microTran"
              >
                query_stats
              </span>
            </p>
          </div>
          <div
            @click="openFundamentanalysis"
            v-if="$store.state.screenSizes.mode == 'desktop'"
            class="flex items-center md:hover:cursor-pointer mx-2"
          >
            <p
              class="rounded-full bg-bgLightest icon-background-size centerAll"
            >
              <span
                class="material-icons-outlined text-primary md:hover:text-white microTran"
              >
                description
              </span>
            </p>
          </div>
          <AddRocketCoins
            v-if="type == 'top100'"
            class="md:hover:cursor-pointer"
            :slug="coin.code"
          />
          <p
            class="flex rounded-full justify-center items-center icon-background-size bg-bgLightest ml-2 microTran"
            :class="openDetails ? 'bg-primary' : ''"
            @click.passive="toggleSingleCoin()"
          >
            <span
              class="material-icons-outlined microTran md:hover:text-white"
              :class="
                openDetails ? ['text-white', 'rotate-180'] : 'text-primary'
              "
            >
              expand_more
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="openDetails" @click.self="openDetails = false" class="mx-2">
      <div>
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">Rang:</p>
          <p class="font-bold text-white text-xs">{{ coinRank }}</p>
        </div>
        <hr class="border-bgLightest" />
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">Market Cap:</p>
          <p class="font-bold text-white text-xs">
            {{ getHumanReadable(coin.cap) }} $
          </p>
        </div>
        <hr class="border-bgLightest" />
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">24h Volumen</p>
          <p class="font-bold text-white text-xs">
            {{ getHumanReadable(coin.volume) }} $
          </p>
        </div>
        <hr class="border-bgLightest" />
        <p class="m-3 text-secondary text-xs">Kursveränderungen in %</p>
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">1h</p>
          <p
            class="font-bold text-white text-xxs"
            v-html="getCourseDelta(coin.delta.hour)"
          ></p>
        </div>
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">24h</p>
          <p
            class="font-bold text-white text-xxs"
            v-html="getCourseDelta(coin.delta.day)"
          ></p>
        </div>
        <div class="flex justify-between m-3">
          <p class="text-secondary text-xs">eine Woche</p>
          <p
            class="font-bold text-white text-xxs"
            v-html="getCourseDelta(coin.delta.week)"
          ></p>
        </div>
        <hr class="border-bgLightest" />
      </div>
      <hr class="border-bgLightest" />
      <div
        class="flex justify-between m-3"
        v-if="$store.state.screenSizes.mode == 'mobile'"
      >
        <div
          @click="openChartanalysis"
          class="flex items-center md:hover:cursor-pointer"
        >
          <p class="rounded-full bg-bgLightest icon-background-size centerAll">
            <span class="material-icons-outlined text-primary">
              query_stats
            </span>
          </p>
          <p class="text-secondary ml-2">Chartanalyse</p>
        </div>
        <div
          @click="openFundamentanalysis"
          class="flex items-center md:hover:cursor-pointer"
        >
          <p class="rounded-full bg-bgLightest icon-background-size centerAll">
            <span class="material-icons-outlined text-primary">
              description
            </span>
          </p>
          <p class="text-secondary ml-2">Fundamentalanalyse</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseMixins from "@/mixins/baseMixins";
import AddRocketCoins from "@/components/AddRocketCoins";
export default {
  name: "SingleCoin",
  mixins: [baseMixins],
  components: {
    AddRocketCoins,
  },
  methods: {
    toggleSingleCoin() {
      if (this.openDetails) {
        this.openDetails = false;
      } else {
        this.openDetails = true;
      }
    },
    openChartanalysis() {
      this.$router.push("/top100/chart/" + this.coin.code.toLowerCase());
    },
    openFundamentanalysis() {
      this.$router.push("/top100/fundament/" + this.coin.code.toLowerCase());
    },
    getCourseDelta(value) {
      let returnVal;
      if (value > 1) {
        returnVal =
          "<span class='text-crazyGreen'>" +
          ((value - 1) * 100).toFixed(2) +
          "%" +
          "</span>";
      } else {
        returnVal =
          "<span class='text-crazyRed'>" +
          ((1 - value) * 100).toFixed(2) +
          "%" +
          "</span>";
      }
      return returnVal;
    },
  },
  data() {
    return {
      openDetails: false,
    };
  },
  props: {
    coinRank: Number,
    coin: Object,
    type: {
      default: "",
      type: String,
    },
  },
};
</script>
